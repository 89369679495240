import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";

class Filter extends Component {
    state = {
        selectedClass: null,
        isChecked: false,
    };

    render() {
        const { selectedClass } = this.state;

        const {
            language,
            data,
            i18n: { filter, resetFilter, Classes1 },
        } = this.props;

        // Push new filter to state

        return (
            <div className={`products-filter ${language === "rtl" && "products-filter-arabic"}`}>
                <div className="FilterTitle">
                    <h1>{filter}</h1>
                    <a
                        onClick={() => {
                            this.props.getFilterData(0);
                            this.setState({ selectedClass: null });
                        }}
                    >
                        {resetFilter}
                    </a>
                </div>
                <div className="AccordionFilters">
                    <Accordion accordion={true} defaultActiveKey={0} expandMode={"multiple"} flush>
                        {/* Classes Accordion */}
                        <Accordion.Item eventKey={0}>
                            <Accordion.Header>{Classes1}</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    {data?.map(({ name, id }) => {
                                        return (
                                            <li key={id}>
                                                <label className={`CehckBoxContainer ${+selectedClass === +id && "CehckBoxContainerActive"}`}>
                                                    {name}

                                                    <input
                                                        type="checkbox"
                                                        checked={+selectedClass === +id && "checked"}
                                                        onClick={() => {
                                                            this.selectFilter(id);
                                                        }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        {/* Classes Accordion End */}
                    </Accordion>
                </div>
            </div>
        );
    }

    sendFilterData = () => {
        this.props.getFilterData({
            options_values: this.state.options_values,
            brands: this.state.brands,
            rate: this.state.rate,
            price_from: this.state.price_from,
            price_to: this.state.price_to,
            free_shipping: this.state.free_shipping,
            new_arrivals: this.state.new_arrivals,
            discount: this.state.discount,
        });
    };

    selectFilter = (id) => {
        this.setState(
            {
                selectedClass: id,
            },
            () => {
                this.props.getFilterData(id);
                this.props.closeModal(false);
            }
        );
    };

    resetFilter = () => {
        const { options_values, brands, rate, price_from, price_to, free_shipping, new_arrivals, discount } = this.state;
        this.setState({
            options_values: [],
            brands: [],
            rate: "",
            price_from: "",
            price_to: "",
            free_shipping: false,
            discount: false,
            new_arrivals: false,
        });
        this.props.getFilterData({
            options_values,
            brands,
            rate,
            price_from,
            price_to,
            free_shipping,
            new_arrivals,
            discount,
        });
    };
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps)(Filter);
