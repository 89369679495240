import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";

import { apiService } from "../../services/ApiService";
import { generalServices } from "../../services/GeneralServices";
import MainLoader from "./MainLoader";
import { useSelector } from "react-redux";
import Global from "../../language/Global.json";
function Footer() {
    const [footerData, setFooterData] = useState({});
    const [pageLoader, setPageLoader] = useState(true);
    const [modal, setModal] = useState(false);
    const [branchData, setBranchData] = useState({});
    const [socialLinks, setSocialLinks] = useState([]);
    const [SettingData, setSettingData] = useState({});
    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {
        getFooterData();

        //getSepratePhoneNumber(SettingData?.phone);
    }, []);

    const getFooterData = async () => {
        const { success, data } = await generalServices.getFooterData();

        if (!success) return;

        setFooterData(data);
        getSocialLinks();
    };

    const getSettings = async () => {
        const { success, data } = await generalServices.getSettings();

        if (!success) return;

        setSettingData(data);
        setPageLoader(false);
    };

    const getSocialLinks = async () => {
        const { success, data } = await generalServices.getSocialLinks();
        if (!success) return;
        setSocialLinks(data);
        getSettings();
        // setPageLoader(false);
    };
    const closeModal = () => {
        setModal(false);
    };
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ar" ? Global.ar : Global.en;
    const {
        AgentsAndDistributors,
        Email,
        Phone,
        QuickLinks,
        ContactUs,
        Home,
        Company,
        Address,
        CoppyRights,
    } = i18n;

    const getSepratePhoneNumber = (phoneNumberString) => {
        if (phoneNumberString) {
            var zipcode = phoneNumberString.slice(0, 5);
            var phone = phoneNumberString.slice(-8);
            var phone =
                phone.substring(0, 3) +
                "-" +
                phone.substring(3, 6) +
                "-" +
                phone.substring(6, 11);

            return "(" + zipcode + ") " + phone;
        } else {
            return "";
        }
    };
    return pageLoader ? (
        <MainLoader />
    ) : (
        <>
            <div className={`footer ${language === "ar" && "footer-arabic"}`}>
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className="footer__lift-side">
                                <h3>{AgentsAndDistributors}</h3>
                                <ul>
                                    {footerData?.branches?.map((item, id) => {
                                        return (
                                            <li
                                                key={id}
                                                onClick={() => {
                                                    setBranchData(item);

                                                    setModal(true);
                                                }}
                                            >
                                                <img
                                                    src={
                                                        apiService.imageLink +
                                                        item.flag
                                                    }
                                                    alt="k"
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="footer__mid">
                                <h3>{ContactUs}</h3>
                                <ul>
                                    <li>
                                        <p>{Email}</p>
                                        <span>
                                            <a
                                                href={
                                                    "mailto:" +
                                                    SettingData?.email
                                                }
                                                style={{
                                                    textTransform: "inherit",
                                                }}
                                            >
                                                {SettingData?.email}
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <p>{Address}</p>

                                        <span>{SettingData?.address}</span>
                                    </li>
                                    <li>
                                        <p>{Phone}</p>
                                        <span>
                                            <a
                                                href={
                                                    "tel:" + SettingData?.phone
                                                }
                                            >
                                                {SettingData?.phone
                                                    ? getSepratePhoneNumber(
                                                          SettingData.phone
                                                      )
                                                    : null}{" "}
                                                <br />
                                            </a>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="footer__mid">
                                <h3>{QuickLinks}</h3>
                                <ul>
                                    <li>
                                        <a href="/">{Home}</a>
                                    </li>
                                    {footerData?.pages?.map(
                                        ({ title, machine_name, id }) => {
                                            return (
                                                <li key={id}>
                                                    <a
                                                        href={`/PublicPage/${machine_name}`}
                                                    >
                                                        {title}
                                                    </a>
                                                </li>
                                            );
                                        }
                                    )}
                                    <li>
                                        <a href={`/ContactUs`}>{ContactUs}</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>{" "}
                    </Row>
                </Container>
            </div>
            <div className="copy-write">
                <Container>
                    <div className=" flex">
                        <p>{CoppyRights}</p>
                        <ul>
                            {socialLinks.map(({ icon, link, id }) => {
                                return (
                                    <li key={id}>
                                        <a href={`${link}`}>
                                            <i className={`fab fa-${icon}`}></i>
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </Container>
            </div>

            {/* add/edit address modal */}
            <Modal
                show={modal}
                width="600"
                effect="fadeInUp"
                onHide={closeModal}
                className="location-modal footer__branch-data-modal"
            >
                <div className="modal-title flex">
                    {/* <h5>{BranchInformation}</h5> */}
                    <h5>{branchData.name}</h5>

                    <i onClick={closeModal} className="fa fa-times"></i>
                </div>
                <ul>
                    <li>
                        <span>{Company} : </span>
                        <span>
                            {branchData.link === null ? (
                                <span>{branchData.name}</span>
                            ) : (
                                <a href={`${branchData.link}`}>
                                    {branchData.name}
                                </a>
                            )}
                        </span>
                    </li>

                    <li>
                        <span>{Email} : </span>
                        <span>{branchData.email}</span>
                    </li>

                    <li>
                        <span>{Phone} : </span>
                        <span>
                            <a href={"tel:" + branchData.phone}>
                                {branchData.phone}
                            </a>
                        </span>
                    </li>
                    {branchData.phone2 && (
                        <li>
                            <span>{Phone}2 : </span>
                            <span>
                                <a href={"tel:" + branchData.phone2}>
                                    {branchData.phone2}
                                </a>
                            </span>
                        </li>
                    )}

                    <li>
                        <span>{Address} : </span>
                        <span>{branchData.address}</span>
                    </li>
                </ul>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3014.577205211585!2d35.99852782633892!3d31.99483310680974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151b60d4671037c7%3A0x10c9531afc09e061!2zQWxoYWRhZiBpbnRsIENvLiBmb3IgaW1wb3J0aW5nICYgaW5kdXN0cnkg2KfZhNmH2K_ZgSDYp9mE2K_ZiNmE2YrYqSDZhNmE2KfYs9iq2YrYsdin2K8g2Ygg2KfZhNi12YbYp9i52KkgUlo!5e0!3m2!1sen!2sjo!4v1631544661040!5m2!1sen!2sjo"
                    width={"100%"}
                    height={450}
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    title="title"
                ></iframe>
            </Modal>
            {/* end add/edit address modal */}
        </>
    );
}

export default Footer;
