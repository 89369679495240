import React, { useEffect, useState } from "react";
import { Col, Form, Row, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { generalServices } from "../../../services/GeneralServices";
import { locationService } from "../../../services/LocationService";
import { displayAlert } from "../../../utils/misc";
import TextInput from "../../blocks/TextInput";
import Global from "../../../language/Global.json";
import { useSelector } from "react-redux";
import { setUser } from "../../../redux/actions-reducers/user";
import { connect } from "react-redux";
import { GoogleApiWrapper } from "google-maps-react";
import LocationPicker from "react-location-picker";

const defaultPosition = {
    lat: 31.963158,
    lng: 35.930359,
};
function Location({
    selectedLocation,
    changeLocation,
    widthCheck,
    locationData,
    getUserAddresses,
    deliveryCost,
}) {
    const [addAddress, setAddAddress] = useState(false);
    const [Countries, setCountries] = useState([]);
    const [fields, setFields] = useState({
        address: locationData?.address ?? "",
        name: locationData?.name ?? "",
        phone: locationData?.phone ?? "",
        country: locationData?.country ?? "",
        longitude: locationData?.longitude ?? "",
        latitude: locationData?.latitude ?? "",
        country_id: locationData?.country_info?.id ?? "",
    });
    const [selectedCountry, setSelectedCountry] = useState([locationData.id]);
    useEffect(() => {
        // console.log(locationData, "locationData");
        getAllCountries();
    }, []);

    const closeModal = () => {
        setAddAddress(false);
    };

    const handleLocationChange = ({ position, address, places }) => {
        // Set new location

        setFields({
            ...fields,
            longitude: position.lng,
            latitude: position.lat,
        });
    };

    const onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            windowProps: props,
        });
    };

    const onMapClick = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    const onFieldChange = (name, value) =>
        setFields({ ...fields, [name]: value });

    const getAllCountries = async () => {
        const { data, success } = await generalServices.getAllCountries();
        if (!success) return;
        setCountries(data);
    };

    const updateLocation = async () => {
        const { Done, LocationEdited } = i18n;
        const { success } = await locationService.updateLocation(
            locationData.id,
            fields
        );

        if (!success) return;
        displayAlert(Done, LocationEdited, "success").then(() => {
            setAddAddress(false);
            getUserAddresses();
        });
    };

    const deleteLocation = async () => {
        const { success } = await locationService.deleteLocation(
            locationData.id
        );
        const { Done, LocationDeleted } = i18n;
        if (!success) return;
        displayAlert(Done, LocationDeleted, "success").then(() => {
            getUserAddresses();
        });
    };
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ar" ? Global.ar : Global.en;
    const {
        EditYourLocation,
        Name,
        Phone,
        Address,
        UpdateLocation,
        TheCountry,
    } = i18n;
    /* Default position */
    const defaultPosition = {
        lat: parseFloat(locationData?.latitude) ?? 31.963158,
        lng: parseFloat(locationData?.longitude) ?? 35.930359,
    };
    return (
        <div
            className="location"
            onClick={() => {
                // setSelectedLocation(id);
                if (changeLocation) {
                    changeLocation(locationData);
                }
            }}
        >
            <div className="location__header flex">
                <h6>{locationData?.name}</h6>
                <button
                    onClick={() => {
                        setAddAddress(true);
                    }}
                >
                    <i className="fa fa-pen"></i>
                </button>
            </div>
            <p>{locationData?.address}</p>
            <div className="location-footer flex">
                <span>{locationData?.phone}</span>
                {widthCheck ? (
                    <div className="flex">
                        <span
                            style={
                                selectedLocation?.id === locationData?.id
                                    ? { backgroundColor: "#50a433 " }
                                    : null
                            }
                        >
                            <i className="fa fa-check"></i>
                        </span>
                    </div>
                ) : (
                    <button
                        onClick={() => {
                            deleteLocation();
                        }}
                        className="location__delete"
                    >
                        <i className="fa fa-trash-alt"></i>
                    </button>
                )}
            </div>

            {/* add/edit address modal */}
            <Modal
                show={addAddress}
                width="600"
                effect="fadeInUp"
                onHide={closeModal}
                className="location-modal"
            >
                <div className="modal-title flex">
                    <h5>{EditYourLocation}</h5>
                    <i onClick={closeModal} className="fa fa-times"></i>
                </div>
                <div className="address-inputs">
                    <Row>
                        <Col
                            lg={6}
                            md={12}
                            sm={12}
                            style={{ marginBottom: "1rem" }}
                        >
                            <TextInput
                                name="name"
                                label={Name}
                                placeholder={Name}
                                value={fields.name}
                                onFieldChange={onFieldChange}
                            />
                        </Col>
                        <Col
                            lg={6}
                            md={12}
                            sm={12}
                            style={{ marginBottom: "1rem" }}
                        >
                            <TextInput
                                name="phone"
                                label={Phone}
                                placeholder={Phone}
                                value={fields.phone}
                                onFieldChange={onFieldChange}
                            />
                        </Col>

                        <Col
                            lg={12}
                            md={12}
                            sm={12}
                            style={{ marginBottom: "1rem" }}
                        >
                            <Form.Select
                                aria-label="Default select example"
                                defaultValue={fields.country_id}
                                onChange={({ target }) => {
                                    setFields({
                                        ...fields,
                                        country_id: target.value,
                                    });
                                }}
                            >
                                {/* <option
                                    defaultValue={
                                        Countries.find(
                                            ({ id }) => id == fields.country_id
                                        )?.name
                                    }
                                >
                                    {
                                        Countries.find(
                                            ({ id }) => id == fields.country_id
                                        )?.name
                                    }
                                </option> */}

                                {/* <option defaultValue={fields.country}>
                                    {fields.country}
                                </option> */}
                                <option value="-1" disabled>
                                    {TheCountry}
                                </option>

                                {Countries.map(({ name, id }) => {
                                    return (
                                        <option
                                            selected={
                                                fields.country_id == id &&
                                                fields.country_id
                                            }
                                            key={id}
                                            value={id}
                                        >
                                            {name}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Col>

                        <Col
                            lg={12}
                            md={12}
                            sm={12}
                            style={{ marginBottom: "1rem" }}
                        >
                            <TextInput
                                name="address"
                                label={Address}
                                placeholder={Address}
                                value={fields.address}
                                onFieldChange={onFieldChange}
                                isTextArea
                            />
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <LocationPicker
                                className="Maps"
                                containerElement={
                                    <div
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            marginTop: "15px",
                                        }}
                                    />
                                }
                                mapElement={
                                    <div
                                        Marker="/img/heart.png"
                                        style={{
                                            height: "400px",
                                        }}
                                    />
                                }
                                defaultPosition={defaultPosition}
                                onChange={handleLocationChange}
                                circleOptions={{
                                    visible: false,
                                }}
                            />
                        </Col>

                        <Col lg={12} md={12} sm={12} className="locationButton">
                            <button
                                className="submit-button"
                                onClick={() => {
                                    updateLocation();
                                }}
                            >
                                {UpdateLocation}
                            </button>
                        </Col>
                    </Row>
                </div>
            </Modal>
            {/* end add/edit address modal */}
        </div>
    );
}

const mapStateToProps = ({ currentUser, language }) => ({
    currentUser,
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
});

//export default connect(mapStateToProps, { setUser })(Cart);
export default connect(mapStateToProps, { setUser })(
    GoogleApiWrapper({
        apiKey: "AIzaSyBeEQoJ2XPNATY5YWXatV45hD6DyGt7Y0s",
    })(Location)
);
