import React, { Component } from "react";
import { Col, Container, Row, Modal, Form } from "react-bootstrap";
import { generalServices } from "../../../services/GeneralServices";
import { locationService } from "../../../services/LocationService";
import { displayAlert } from "../../../utils/misc";
import MainLoader from "../../blocks/MainLoader";
import TextInput from "../../blocks/TextInput";
import AgentOf from "../home/AgentOf";
import Location from "./Location";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";

import { GoogleApiWrapper } from "google-maps-react";
import LocationPicker from "react-location-picker";
import { profileService } from "../../../services/Profile";
/* Default position */
const defaultPosition = {
    lat: 31.963158,
    lng: 35.930359,
};

class MyLocations extends Component {
    state = {
        AddAddress: false,
        pageLoader: true,
        locations: [],
        countries: [],
        selectedCountry: {},
        position: {
            lat: 31.963158,
            lng: 35.930359,
        },
        fields: {
            address: "",
            name: "",
            phone: "",
            country: "",
            longitude: defaultPosition.lng,
            latitude: defaultPosition.lat,
            country_id: "",
        },
    };

    componentDidMount() {
        Promise.all([this.getAllCountries(), this.getUserAddresses()]);
    }

    getAllCountries = async () => {
        const { data } = await generalServices.getAllCountries();
        this.setState({
            countries: data,
            fields: {
                ...this.state.fields,
                // country: data[0]?.name,
                // country_id: data[0]?.id,
            },
        });
    };

    getUserAddresses = async () => {
        const { success, data } = await locationService.getUserAddresses();

        if (!success) return;

        this.setState({
            locations: data,
            pageLoader: false,
        });
    };

    closeModal = () => {
        this.setState({
            AddAddress: false,
        });
    };

    handleLocationChange = ({ position, address, places }) => {
        // Set new location
        this.setState({
            position,
            address,
            fields: {
                ...this.state.fields,
                longitude: position.lng,
                latitude: position.lat,
            },
        });
    };

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            windowProps: props,
        });
    };
    onMapClick = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    render() {
        const onFieldChange = (name, value) =>
            this.setState({ fields: { ...fields, [name]: value } });
        const {
            fields,
            AddAddress,
            locations,
            pageLoader,
            countries,
            selectedCountry,
        } = this.state;
        const {
            i18n: {
                MyLocations,
                AddLocation,
                AddYourLocation,
                Name,
                Phone,
                Address,
                TheCountry,
            },
            language,
        } = this.props;
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div
                className={`contact-us auth ${
                    language === "ar" && "contact-us-arabic my-locations-arabic"
                }`}
            >
                <div className="page-label" />
                <Container>
                    <h3 className="page-title">{MyLocations}</h3>
                    <div className="form-box">
                        <Row>
                            <Col md={6}>
                                {locations.map((item, id) => {
                                    return (
                                        <Location
                                            key={id}
                                            widthCheck={false}
                                            locationData={item}
                                            getUserAddresses={() =>
                                                this.getUserAddresses()
                                            }
                                        />
                                    );
                                })}
                                <button
                                    className="submit-button"
                                    onClick={() =>
                                        this.setState({ AddAddress: true })
                                    }
                                >
                                    {AddLocation}
                                </button>
                            </Col>

                            <Col md={6}>
                                {/* <img scc="/images/" alt="l" /> */}
                                <img
                                    src="/images/Group-50411.png"
                                    alt="l"
                                    className="main-form-img"
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
                <AgentOf />

                {/* add address modal */}
                <Modal
                    show={AddAddress}
                    width="600"
                    effect="fadeInUp"
                    onHide={this.closeModal}
                    className="location-modal"
                >
                    <div className="modal-title flex">
                        <h5>{AddYourLocation}</h5>
                        <i
                            onClick={this.closeModal}
                            className="fa fa-times"
                        ></i>
                    </div>
                    <div className="address-inputs">
                        <Row>
                            <Col
                                lg={6}
                                md={12}
                                sm={12}
                                style={{ marginBottom: "1rem" }}
                            >
                                <TextInput
                                    name="name"
                                    label={Name}
                                    placeholder={Name}
                                    value={fields.name}
                                    onFieldChange={onFieldChange}
                                />
                            </Col>
                            <Col
                                lg={6}
                                md={12}
                                sm={12}
                                style={{ marginBottom: "1rem" }}
                            >
                                <TextInput
                                    name="phone"
                                    label={Phone}
                                    placeholder={Phone}
                                    value={fields.phone}
                                    onFieldChange={onFieldChange}
                                />
                            </Col>
                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{ marginBottom: "1rem" }}
                            >
                                <TextInput
                                    name="address"
                                    label={Address}
                                    placeholder={Address}
                                    value={fields.address}
                                    onFieldChange={onFieldChange}
                                    isTextArea
                                />
                            </Col>
                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{ marginBottom: "1rem" }}
                            >
                                <Form.Select
                                    defaultValue={"-1"}
                                    aria-label="Default select example"
                                    onChange={({ target }) => {
                                        this.setState({
                                            fields: {
                                                ...fields,
                                                country_id: JSON.parse(
                                                    target.value
                                                ).id,
                                                country: JSON.parse(
                                                    target.value
                                                ).name,
                                            },
                                        });
                                    }}
                                >
                                    <option value="-1" disabled>
                                        {TheCountry}
                                    </option>
                                    {countries.map(({ name, id }) => {
                                        return (
                                            <option
                                                key={id}
                                                value={JSON.stringify({
                                                    name,
                                                    id,
                                                })}
                                            >
                                                {name}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Col>

                            <Col lg={12} md={12} sm={12}>
                                <LocationPicker
                                    className="Maps"
                                    containerElement={
                                        <div
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                marginTop: "15px",
                                            }}
                                        />
                                    }
                                    mapElement={
                                        <div
                                            Marker="/img/heart.png"
                                            style={{
                                                height: "400px",
                                            }}
                                        />
                                    }
                                    defaultPosition={defaultPosition}
                                    onChange={this.handleLocationChange}
                                    circleOptions={{
                                        visible: false,
                                    }}
                                />
                            </Col>

                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                className="locationButton"
                            >
                                <button
                                    className="submit-button"
                                    onClick={() => {
                                        this.addLocation();
                                    }}
                                >
                                    {AddLocation}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                {/* end add address modal */}
            </div>
        );
    }

    addLocation = async () => {
        const { fields } = this.state;
        const { success } = await locationService.addLocation(fields);
        const { Done, LocationAdded, Warning, FillAllFields } = this.props.i18n;
        if (!success) return displayAlert(Warning, FillAllFields, "error");
        displayAlert(Done, LocationAdded, "success").then(() => {
            this.getUserAddresses();

            this.setState({
                AddAddress: false,
                fields: {
                    address: "",
                    name: "",
                    phone: "",
                    country: "",
                    longitude: "",
                    latitude: "",
                },
            });
        });
    };

    updateLocation = async () => {
        const { fields } = this.state;
        const { success } = await locationService.updateLocation(fields);
        const { Done, LocationAdded } = this.props.i18n;
        if (!success) return;
        displayAlert(Done, LocationAdded, "success").then(() => {
            this.getUserAddresses();
            this.setState({
                AddAddress: false,
                fields: {
                    address: "",
                    name: "",
                    phone: "",
                    country: "",
                    longitude: "",
                    latitude: "",
                },
            });
        });
    };
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(MyLocations);
