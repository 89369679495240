import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/actions-reducers/user";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralServices";
import Global from "../../../language/Global.json";
import { displayAlert } from "../../../utils/misc";
function BigSale({ data, getHomeData }) {
    const currentUser = useSelector(({ currentUser }) => currentUser);
    const dispatch = useDispatch();
    const [qtyIsEmpty, setQtyIsEmpty] = useState(
        data.quantity <= 0 ? false : true
    );

    const addRemoveFavorites = async (id, is_fav, getHomeData) => {
        const { success } = await generalServices.addOrRemoveFavourite(id);
        if (!success) return;
        dispatch(
            setUser({
                fav_count: is_fav
                    ? currentUser.fav_count - 1
                    : currentUser.fav_count + 1,
            })
        );

        getHomeData();
    };

    const addBooksToCart = async (id, qty, reFetchData) => {
        const { success } = await generalServices.addToCart(id, qty);
        if (!success) return;
        dispatch(setUser({ cart_count: currentUser.cart_count + 1 }));

        reFetchData();
    };

    const deleteBooksFromCart = async (id, reFetchData) => {
        const { success } = await generalServices.deleteBooksFromCart(id);
        if (!success) return;
        dispatch(setUser({ cart_count: currentUser.cart_count - 1 }));

        reFetchData();
    };
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ar" ? Global.ar : Global.en;
    const {
        BigSale,
        AddToCart,
        WishList,
        Success,
        DeletedFromCart,
        Done,
        AddedToCart,
    } = i18n;

    return (
        <div className={`big-sale ${language === "ar" && "big-sale-arabic"}`}>
            <Container>
                <div className="section-title">
                    <h3>{BigSale}</h3>
                </div>
                <Row>
                    {data
                        .map(
                            (
                                {
                                    id,
                                    key,
                                    title,
                                    price,
                                    quantity,
                                    main_image,
                                    description,
                                    campaign,
                                    is_fav,
                                    in_cart,
                                    min_order,
                                },
                                index
                            ) => {
                                return (
                                    <Col key={id} md={6}>
                                        <div className="big-sale__card">
                                            <a
                                                href={`/productDetails/${id}`}
                                                className="big-sale__card__header"
                                            >
                                                <img
                                                    src={
                                                        campaign?.image
                                                            ? apiService.imageLink +
                                                              campaign?.image
                                                            : "images/placeholder.png"
                                                    }
                                                    alt="/"
                                                />
                                                <span>
                                                    {" "}
                                                    {campaign
                                                        ? newPrice(
                                                              campaign,
                                                              price
                                                          ) + "$"
                                                        : null}
                                                </span>
                                            </a>
                                            <div className="big-sale__card__contents">
                                                <h3
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "/productDetails/" +
                                                            id)
                                                    }
                                                >
                                                    {" "}
                                                    {title}
                                                </h3>
                                                <p>{description}</p>

                                                <div className="big-sale__card--buttons flex">
                                                    {window.localStorage.getItem(
                                                        "accessToken"
                                                    ) ? (
                                                        //
                                                        qtyIsEmpty ? (
                                                            <button
                                                                className={
                                                                    in_cart
                                                                        ? "add-to-cart flex added-to-cart"
                                                                        : "add-to-cart flex"
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        in_cart
                                                                    ) {
                                                                        deleteBooksFromCart(
                                                                            id,
                                                                            getHomeData
                                                                        ).then(
                                                                            () => {
                                                                                displayAlert(
                                                                                    Success,
                                                                                    DeletedFromCart,
                                                                                    "success",
                                                                                    Done,
                                                                                    3000
                                                                                );
                                                                            }
                                                                        );
                                                                    } else {
                                                                        addBooksToCart(
                                                                            id,
                                                                            min_order,
                                                                            getHomeData
                                                                        ).then(
                                                                            () => {
                                                                                displayAlert(
                                                                                    Success,
                                                                                    AddedToCart,
                                                                                    "success",
                                                                                    Done,
                                                                                    3000
                                                                                );
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src="/images/Group 511.svg"
                                                                    alt="/"
                                                                />
                                                                <span>
                                                                    {AddToCart}
                                                                </span>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                title="Out of stock"
                                                                className={
                                                                    "add-to-cart flex out_of_stock"
                                                                }
                                                                disabled={true}
                                                            >
                                                                Out Of Stock
                                                            </button>
                                                        )
                                                    ) : (
                                                        <a
                                                            href="/Login"
                                                            className={
                                                                in_cart
                                                                    ? "add-to-cart flex added-to-cart"
                                                                    : "add-to-cart flex"
                                                            }
                                                            onClick={() => {
                                                                if (in_cart) {
                                                                    deleteBooksFromCart(
                                                                        id,
                                                                        getHomeData
                                                                    );
                                                                } else {
                                                                    addBooksToCart(
                                                                        id,
                                                                        1,
                                                                        getHomeData
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <img
                                                                src="/images/Group 511.svg"
                                                                alt="/"
                                                            />
                                                            <span>
                                                                {AddToCart}
                                                            </span>
                                                        </a>
                                                    )}
                                                    {window.localStorage.getItem(
                                                        "accessToken"
                                                    ) ? (
                                                        <button
                                                            className={
                                                                is_fav
                                                                    ? "add-to-fav--active add-to-fav flex"
                                                                    : "add-to-fav flex"
                                                            }
                                                            onClick={() =>
                                                                addRemoveFavorites(
                                                                    id,
                                                                    is_fav,
                                                                    getHomeData
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src="/images/fi-rr-heart2.svg"
                                                                alt="/"
                                                            />
                                                            <span>
                                                                {WishList}
                                                            </span>
                                                        </button>
                                                    ) : (
                                                        <a
                                                            href="/Login"
                                                            className={
                                                                is_fav
                                                                    ? "add-to-fav--active add-to-fav flex"
                                                                    : "add-to-fav flex"
                                                            }
                                                            onClick={() =>
                                                                addRemoveFavorites(
                                                                    id,
                                                                    is_fav,
                                                                    getHomeData
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src="/images/fi-rr-heart2.svg"
                                                                alt="/"
                                                            />
                                                            <span>
                                                                {WishList}
                                                            </span>
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            }
                        )}
                </Row>
            </Container>
        </div>
    );
}
const newPrice = (campaign, price) => {
    let discountAmount = (price * campaign.percentage) / 100;

    let priceAfterDiscount = 0;
    if (discountAmount > campaign.fixed_amount) {
        priceAfterDiscount = price - campaign.fixed_amount;

        return priceAfterDiscount;
    } else {
        priceAfterDiscount = price - discountAmount;
        return priceAfterDiscount;
    }
};
export default BigSale;
