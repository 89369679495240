import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { generalServices } from "../../../services/GeneralServices";
import MainLoader from "../../blocks/MainLoader";
import Product from "../../blocks/Product";
import AgentOf from "../home/AgentOf";
import NewTitles from "../home/NewTitles";
import { connect } from "react-redux";
import { setLanguage } from "../../../redux/actions-reducers/language";
import Global from "../../../language/Global.json";

class Search extends Component {
    state = {
        books: [],
        pageLoader: true,
    };

    componentDidMount() {
        this.searchBook();
    }
    searchBook = async () => {
        const { success, data } = await generalServices.searchBook(
            this.props.match.params.key
        );

        if (!success) return;

        this.setState({
            books: data,
            pageLoader: false,
        });
    };

    render() {
        const { pageLoader, books, relatedBooks } = this.state;
        const {
            language,
            i18n: { NoResultsFound },
        } = this.props;
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div
                className={`contact-us ${
                    language === "ar" && "contact-us-arabic"
                }`}
            >
                <div className="page-label" />
                <div className="favorites-products">
                    <Container>
                        <h3 className="page-title SearchTitle">
                            {this.props.match.params.key}
                        </h3>
                        <Row>
                            {books.length > 0 ? (
                                books?.map((item) => {
                                    return (
                                        <Col sm={6} md={4} xl={3}>
                                            <Product
                                                item={item}
                                                reFetchData={() =>
                                                    this.searchBook()
                                                }
                                            />
                                        </Col>
                                    );
                                })
                            ) : (
                                <div className="form-box">
                                    <div className="empty-img">
                                        <img src="/images/empty.png" alt="l" />
                                        <h6>{NoResultsFound}</h6>
                                    </div>
                                </div>
                            )}
                        </Row>
                    </Container>
                </div>

                <AgentOf />
            </div>
        );
    }
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(Search);
