import React, { Component } from "react";
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { apiService } from "../../services/ApiService";
import { bookService } from "../../services/bookService";
import { generalServices } from "../../services/GeneralServices";
import Global from "../../language/Global.json";

import { connect } from "react-redux";
export class TitlesMenu extends Component {
    state = {
        sub_1: [],
        sub_2: [],
        classes: [],
        series: [],
        sub_one_machine: "",
        sub_two_machine: "",
        bgColor: "",
        stages: [],
        active_tap: "",
        stage_id: "",
        subjects: [],
        subject_id: "",
    };

    getStages = async () => {
        const { success, data } = await generalServices.getStages();
        if (!success) return;
        this.setState({ stages: data });

        console.log(data, "stages");
    };

    getSubjectByStage = async () => {
        const { success, data } = await generalServices.getSubjectByStage(this.state.stage_id);
        if (!success) return;

        this.setState({ subjects: data });

        console.log(data, "classes");
    };

    getClassBySubject = async () => {
        const { success, data } = await generalServices.getClassBySubject(this.state.subject_id, this.state.stage_id);
        if (!success) return;
        this.setState({ classes: data });

        console.log(data, "classes");
    };

    getSeries = async () => {
        const { success, data } = await bookService.getSeries();
        if (!success) return;
        this.setState({
            series: data,
        });
    };

    // rgb(17 113 165) 0px 0px 17px 0px
    componentDidMount() {
        // this.getStages();
    }

    openSub(level, content, id) {
        if (level === 1) {
            this.setState({
                sub_1: content,
                sub_2: [],
                sub_one_machine: id,
            });
        } else {
            this.setState({
                sub_2: content,
                sub_two_machine: id,
                active_tap: id,
            });
        }
    }
    render() {
        const { classes, sub_1, sub_2, bgColor, stages, subjects } = this.state;

        return (
            <>
                <Container className="title-menu" data-aos="fade-up" data-aos-offset="200" data-aos-delay="3" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true" data-aos-anchor-placement="top-bottom">
                    <div
                        className="title-menu-container"
                        style={{
                            marginTop: "40px",
                            display: "grid",
                            gridTemplateColumns: `repeat(${this.props.stages.length}, 1fr)`,
                            gridTemplateRows: `1fr`,
                            overflowX: "scroll",
                            width: "1219px",
                        }}
                    >
                        {this.props.stages?.map(({ name, subjects: classes, id, image, background_color }, index) => {
                            // console.log(classes, "classes");

                            return (
                                <div className="text-center d-flex p-5 pb-0" key={index}>
                                    <div
                                        className="menu-node"
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    bgColor: background_color,
                                                    stage_id: id,
                                                    classes: [],
                                                },
                                                () => this.getSubjectByStage()
                                            );
                                            // this.openSub(1, classes, id);
                                        }}
                                    >
                                        <img
                                            src={apiService.imageLink + image}
                                            style={{
                                                maxWidth: "100%",
                                                objectFit: "contain",
                                                height: "100px",
                                            }}
                                        />
                                        <div className="menu-node__body">
                                            <p
                                                className="menu-node__body__title"
                                                style={{
                                                    // color: `${`#1171A5`}`,
                                                    color: background_color,
                                                }}
                                            >
                                                {name}
                                            </p>
                                        </div>
                                        {this.state.sub_one_machine == id && (
                                            <div
                                                className=""
                                                id="triangle-up"
                                                style={{
                                                    borderBottom: `10px solid ${bgColor}`,
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Container>

                {subjects?.length > 0 && (
                    <Container>
                        <div className=" dd">
                            {subjects.map((item, index) => {
                                return (
                                    <Dropdown.Menu show className="titles-sub" style={{ background: bgColor }} key={index}>
                                        <Dropdown.Item
                                            eventKey={index}
                                            // onClick={() => this.setState({ subject_id: item.id }, () => this.getClassBySubject())}
                                            onClick={() => {
                                                window.location.href = `/${!item.has_one_series ? "CategoryPage" : "CategoryPage2"}/${item.id}${item.has_one_series ? "/" + item.has_one_series : ""}/${this.state.stage_id}`;
                                            }}
                                            className={`titles-sub__item ${this.state.active_tap == item.id ? "active" : "flase"}`}
                                        >
                                            {item.name}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                );
                            })}
                        </div>
                    </Container>
                )}

                {/* {classes?.length > 0 && (
                    <Container>
                        <div className="dd">
                            {classes.map((item, index) => {
                                return (
                                    <Dropdown.Menu show className="titles-sub" style={{ background: bgColor, opacity: ".9", color: "#fff" }} key={index}>
                                        <Dropdown.Item
                                            eventKey={index}
                                            onClick={() => {
                                                window.location.href = `/${!item.has_one_series ? "CategoryPage" : "CategoryPage2"}/${item.id}/${this.state.subject_id}${item.has_one_series ? "/" + item.has_one_series : ""}/${this.state.stage_id}`;
                                            }}
                                            className="titles-sub__item"
                                        >
                                            {item.name}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                );
                            })}
                        </div>
                    </Container>
                )} */}
            </>
        );
    }
}

const mapStateToProps = ({ currentUser, language, stages }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
    stages,
});

export default connect(mapStateToProps)(TitlesMenu);
