import React from "react";
import { useSelector } from "react-redux";
import Global from "../../language/Global.json";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
function MultiSideMenu(props) {
    const { menuOpen, options, handleSidebarToggle } = props;
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ar" ? Global.ar : Global.en;
    const { AgentsAndDistributors } = i18n;
    return (
        <div
            className={`multi_container ${
                language === "ar" && "multi_container-arabic"
            }`}
        >
            <MultilevelSidebar
                open={menuOpen}
                onToggle={handleSidebarToggle}
                options={options}
                header={
                    <div
                        className="logo text-center p-3"
                        style={{ backgroundColor: "#fff" }}
                    >
                        <img
                            src="/images/red.eng.log.svg"
                            alt="l"
                            onClick={() => {
                                window.location.href = "/";
                            }}
                        />
                    </div>
                }
                className={`multi-side-menu ${
                    language === "ar" && "multi-side-menu-arabic"
                }`}
            />
        </div>
    );
}
export default MultiSideMenu;
