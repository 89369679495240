import React, { Component } from "react";
import AboutBlock from "./AboutBlock";
import LandingPage from "./LandingPage";
import BigSale from "./BigSale";
import NewTitles from "./NewTitles";
import BigTitle from "./BigTitle";
import AgentOf from "./AgentOf";
import TheBlog from "./TheBlog";
import { homeService } from "../../../services/HomeService";
import MainLoader from "../../blocks/MainLoader";
import { generalServices } from "../../../services/GeneralServices";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { Carousel } from "react-bootstrap";
import Product from "../../blocks/Product";
import TitlesMenu from "../../blocks/TitlesMenu";
class Home extends Component {
    state = {
        books: [],
        featured: [],
        partners: [],
        saleBooks: [],
        blogs: [],
        about: "",
        pageLoader: true,
        StaticImageData: {},
    };
    componentDidMount() {
        this.getHomeData();
        this.getStaticImage();
    }
    getHomeData = async () => {
        const { success, data } = await homeService.getHomeData();

        if (!success) return;
        this.setState({
            books: data.books,
            about: data.about,
            featured: data.featured,
            partners: data.partners,
            saleBooks: data.saleBooks,
            blogs: data.blogs,
            pageLoader: false,
        });
    };
    getStaticImage = async () => {
        const { success, data } = await generalServices.getStaticImage(0);
        if (!success) return;
        this.setState({
            StaticImageData: data,
        });
    };

    render() {
        const {
            books,
            featured,
            saleBooks,
            about,
            pageLoader,
            blogs,
            StaticImageData,
        } = this.state;
        const {
            i18n: { NewTitle, BigSale },
        } = this.props;
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        };
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div className="home">
                <LandingPage data={featured} />

                <AboutBlock StaticImageData={StaticImageData} data={about} />
                {/* {saleBooks?.length !== 0 && (
                    <Carousel
                        className={saleBooks.length < 3 ? "rtl" : "ltr"}
                        responsive={responsive}
                        dotListClass="custom-dot-list-style"
                        arrows={true}
                        infinite={saleBooks.length < 3 ? false : true}
                        itemClass="carousel-item-padding-40-px"
                        autoPlay
                        autoPlaySpeed={3000}
                    >
                        {saleBooks?.map((item) => {
                            console.log(item);
                            return (
                                <Product
                                    className="new-titles-product"
                                    item={item}
                                    reFetchData={this.reFetchData}
                                />
                            );
                        })}
                    </Carousel>
                )} */}

                <div className="mt-5">
                    <TitlesMenu />
                </div>

                <NewTitles
                    title={BigSale}
                    data={books}
                    reFetchData={() => this.getHomeData()}
                    type="bigsale"
                />

                <NewTitles
                    title={NewTitle}
                    data={books}
                    reFetchData={() => this.getHomeData()}
                    type="titles"
                />
                <AgentOf />

                <div className="mt-2 mb-5">
                    <TheBlog data={blogs} />
                </div>

                {/* {saleBooks[2] ? <BigTitle data={saleBooks[2]} /> : null} */}
            </div>
        );
    }
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setLanguage })(Home);
