import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { generalServices } from "../../../services/GeneralServices";
import MainLoader from "../../blocks/MainLoader";
import Product from "../../blocks/Product";
import AgentOf from "../home/AgentOf";
import NewTitles from "../home/NewTitles";
import { connect } from "react-redux";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import Global from "../../../language/Global.json";
import { profileService } from "../../../services/Profile";
import TitlesMenu from "../../blocks/TitlesMenu";
import SeriesBlock from "../../blocks/SeriesBlock";
import { bookService } from "../../../services/bookService";
import Filter from "./Filter";
import FilterModal from "./FilterModal";

class CategoryPage2 extends Component {
    state = {
        seriesBooks: [],
        classes: [],
        class_id: 0,
        isFilterModalShow: false,
    };

    componentDidMount() {
        this.getBooksBySeries(this.props.match.params.series_id, this.props.match.params.subject_id, (this.state.class_id = 0), this.props.match.params.stage_id);

        this.getClassBySubject();
    }

    getClassBySubject = async () => {
        const { success, data } = await generalServices.getClassBySubject(this.props.match.params.subject_id, this.props.match.params.stage_id);
        if (!success) return;
        this.setState({ classes: data });
    };

    getBooksBySeries = async (series_id, subject_id, class_id, stage_id) => {
        const { success, data } = await bookService.getBooksBySeries(series_id, subject_id, class_id, stage_id);

        if (!success) return;
        this.setState({
            seriesBooks: data,
        });
    };

    render() {
        const { pageLoader, relatedBooks, classes } = this.state;
        const {
            i18n: { BooksYouMayLike, filter },
            language,
        } = this.props;

        const closeModal = () => {
            this.setState({ isFilterModalShow: false });
        };

        return pageLoader ? (
            <MainLoader />
        ) : (
            <div className={`contact-us ${language === "ar" && "contact-us-arabic"}`}>
                <div className="favorites-products">
                    {this.state.isFilterModalShow && <FilterModal filterModal={true} data={classes} closeModal={closeModal} getFilterData={(id) => this.getBooksBySeries(this.props.match.params.series_id, this.props.match.params.subject_id, id, this.props.match.params.stage_id)} />}

                    <Container>
                        <div className="mb-5 ">
                            <TitlesMenu />
                        </div>
                        <Row className="pt-5 relative">
                            <Col md={3}>
                                {/* <Filter data={filterData} getFilterData={this.getFilterData} /> */}
                                {window.innerWidth > 768 && <Filter data={classes} closeModal={(state) => this.setState({ isFilterModalShow: false })} getFilterData={(id) => this.getBooksBySeries(this.props.match.params.series_id, this.props.match.params.subject_id, id, this.props.match.params.stage_id)} />}
                                {window.innerWidth < 768 && (
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                isFilterModalShow: true,
                                            })
                                        }
                                        className="filter-icon"
                                    >
                                        <img src="/images/filtergray.svg" width="25" height="23" />
                                        <p>{filter}</p>
                                    </div>
                                )}
                            </Col>
                            <Col md={9}>
                                <Row className="">
                                    {this.state.seriesBooks?.map((item, index) => {
                                        return (
                                            <Col sm={6} md={6} lg={6} xl={4}>
                                                <Product key={index} className="new-titles-product text-center" item={item} reFetchData={item.reFetchData} animate={false} />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <NewTitles title={BooksYouMayLike} data={relatedBooks} reFetchData={() => this.getUserFavorite()} />

                <AgentOf />
            </div>
        );
    }
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(CategoryPage2);
