import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { profileService } from "../../../../services/Profile";
import MainLoader from "../../../blocks/MainLoader";
import AgentOf from "../../home/AgentOf";
import Order from "./Order";
import { setLanguage } from "../../../../redux/actions-reducers/language";
import { setUser } from "../../../../redux/actions-reducers/user";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json";

class MyOrders extends Component {
    state = {
        Orders: [],
        pageLoader: true,
    };

    componentDidMount() {
        this.getOrders();
    }

    getOrders = async () => {
        const { success, data } = await profileService.getOrders();

        if (!success) return;
        this.setState({
            orders: data,
            pageLoader: false,
        });
    };

    render() {
        const {
            i18n: { MyOrders, OrdersEmpty },
            language,
        } = this.props;
        const { orders, pageLoader } = this.state;
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div
                className={`contact-us auth ${
                    language === "ar" && "contact-us-arabic"
                }`}
            >
                <div className="page-label" />
                <Container>
                    <h3 className="page-title">{MyOrders}</h3>
                    <div className="form-box">
                        {orders?.length > 0 ? (
                            <Row>
                                {orders.map((item, index) => {
                                    return (
                                        <Col key={index} xl={6}>
                                            <Order orderData={item} />
                                        </Col>
                                    );
                                })}
                            </Row>
                        ) : (
                            <div className="empty-img">
                                <img src="/images/empty.png" alt="l" />
                                <h6>{OrdersEmpty}</h6>
                            </div>
                        )}
                    </div>
                </Container>
                <AgentOf />
            </div>
        );
    }
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(MyOrders);
