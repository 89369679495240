import React, { Component } from "react";
import { Container } from "react-bootstrap";
import MainLoader from "../../blocks/MainLoader";
import AgentOf from "../home/AgentOf";
import NewTitles from "../home/NewTitles";
import { connect } from "react-redux";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import Global from "../../../language/Global.json";
import TitlesMenu from "../../blocks/TitlesMenu";
import SeriesBlock from "../../blocks/SeriesBlock";
import { bookService } from "../../../services/bookService";

class CategoryPage extends Component {
    state = {
        title: "",
        books: [],
        pageLoader: true,
        booksData: [],
        series: [],
    };

    componentDidMount() {
        this.getSeriesByClass(0, this.props.match.params.subject_id, this.props.match.params.stage_id);
    }

    getSeriesByClass = async (class_id, subject_id, stage_id) => {
        const { success, data } = await bookService.getSeriesByClass(class_id, subject_id, stage_id);
        if (!success) return;

        // if (data.length <= 1 && data.length != 0) {

        //     console.log("data", data);
        //     window.location.href = `/CategoryPage2/${this.props.match.params.class_id}/${this.props.match.params.subject_id}/${this.props.match.params.stage_id}/${data[0].id}`;

        // }
        console.log(data, "data kdkihoiugh");
        this.setState({
            series: data,
            pageLoader: false,
        });
    };

    render() {
        const { pageLoader, relatedBooks } = this.state;
        const {
            i18n: { BooksYouMayLike },
            language,
        } = this.props;
        return pageLoader ? (
            <MainLoader />
        ) : (
            <div className={`contact-us ${language === "ar" && "contact-us-arabic"}`}>
                <div className="favorites-products">
                    <Container>
                        <TitlesMenu />
                        {/* <h3 className="page-title">
                                dvfsdvsdv
                            </h3> */}

                        {this.state.series?.length > 0
                            ? this.state.series?.map((item, index) => {
                                  return <SeriesBlock series={item} key={index} />;
                              })
                            : ""}
                    </Container>
                </div>

                <NewTitles title={BooksYouMayLike} data={relatedBooks} reFetchData={() => this.getUserFavorite()} />

                <AgentOf />
            </div>
        );
    }
}

const mapStateToProps = ({ currentUser, language }) => ({
    language: language.lang,
    i18n: language.lang === "ar" ? Global.ar : Global.en,
    currentUser,
});

export default connect(mapStateToProps, { setUser, setLanguage })(CategoryPage);
