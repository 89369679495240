import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiService } from "../../services/ApiService";
import { generalServices } from "../../services/GeneralServices";
import { setUser } from "../../redux/actions-reducers/user";
import { bookService } from "../../services/bookService";
import { displayAlert } from "../../utils/misc";
import Global from "../../language/Global.json";

export default function SquareProduct({
    item: {
        id,
        main_image_thumb,
        price,
        title,
        description,
        is_fav,
        campaign,
        in_cart,
        quantity,
        min_order,
    },
    reFetchData,
}) {
    const currentUser = useSelector(({ currentUser }) => currentUser);
    const [qtyIsEmpty, setQtyIsEmpty] = useState(quantity <= 0 ? false : true);
    const dispatch = useDispatch();

    const newPrice = () => {
        let discountAmount = (price * campaign.percentage) / 100;

        let priceAfterDiscount = 0;
        if (discountAmount > campaign.fixed_amount) {
            priceAfterDiscount = price - campaign.fixed_amount;

            return priceAfterDiscount;
        } else {
            priceAfterDiscount = price - discountAmount;
            return priceAfterDiscount;
        }
    };

    const addBooksToCart = async (id, qty, reFetchData) => {
        const { success } = await generalServices.addToCart(id, qty);
        if (!success) return;
        dispatch(setUser({ cart_count: currentUser.cart_count + 1 }));

        reFetchData();
    };

    const addRemoveFavorites = async (id, reFetchData) => {
        const { success } = await generalServices.addOrRemoveFavourite(id);
        if (!success) return;
        dispatch(
            setUser({
                fav_count: is_fav
                    ? currentUser.fav_count - 1
                    : currentUser.fav_count + 1,
            })
        );

        reFetchData();
    };

    const deleteBooksFromCart = async (id, reFetchData) => {
        const { success } = await generalServices.deleteBooksFromCart(id);
        if (!success) return;
        dispatch(setUser({ cart_count: currentUser.cart_count - 1 }));

        reFetchData();
    };
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ar" ? Global.ar : Global.en;
    const { Success, AddedToCart, Done, DeletedFromCart } = i18n;
    return (
        <div
            className={`product square-product ${
                language === "ar" && "product-arabic"
            }`}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="3"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
            // data-aos-delay="100"
        >
            <div className="product__header">
                <img
                    src={
                        main_image_thumb
                            ? apiService.imageLink + main_image_thumb
                            : "/images/Group 510@3x.png"
                    }
                    alt="/"
                    className="product__header__book-img"
                    onClick={() =>
                        (window.location.href = "/productDetails/" + id)
                    }
                />
                {window.localStorage.getItem("accessToken") ? (
                    <span
                        className="flex"
                        onClick={() => addRemoveFavorites(id, reFetchData)}
                    >
                        <i
                            className={is_fav ? "fa fa-heart" : "far fa-heart"}
                        ></i>
                    </span>
                ) : (
                    <a
                        href="/Login"
                        className="flex"
                        // onClick={() => addRemoveFavorites(id, reFetchData)}
                    >
                        <i
                            className={is_fav ? "fa fa-heart" : "far fa-heart"}
                        ></i>
                    </a>
                )}
            </div>
            <div className="product__contents">
                <h3
                    onClick={() =>
                        (window.location.href = "/productDetails/" + id)
                    }
                >
                    {title}
                </h3>
                <p>{description}</p>
                <div className="product--buttons flex">
                    {window.localStorage.getItem("accessToken") ? (
                        qtyIsEmpty ? (
                            <button
                                title="Add To Cart"
                                className={
                                    in_cart
                                        ? "add-to-cart flex added-to-cart"
                                        : "add-to-cart flex"
                                }
                                onClick={async () => {
                                    if (in_cart) {
                                        deleteBooksFromCart(
                                            id,
                                            reFetchData
                                        ).then(() => {
                                            displayAlert(
                                                Success,
                                                DeletedFromCart,
                                                "success",
                                                Done,
                                                3000
                                            );
                                        });
                                    } else {
                                        addBooksToCart(
                                            id,
                                            min_order,
                                            reFetchData
                                        ).then(() =>
                                            displayAlert(
                                                Success,
                                                AddedToCart,
                                                "success",
                                                Done,
                                                3000
                                            )
                                        );
                                    }
                                }}
                            >
                                <img src="/images/carticon.svg" alt="/" />
                            </button>
                        ) : (
                            <button
                                title="Out of stock"
                                className={"add-to-cart flex out_of_stock"}
                                disabled={true}
                            >
                                Out Of Stock
                            </button>
                        )
                    ) : (
                        <a
                            href="/Login"
                            title="Add To Cart"
                            className={
                                in_cart
                                    ? "add-to-cart flex added-to-cart"
                                    : "add-to-cart flex"
                            }
                            onClick={() => {
                                if (in_cart) {
                                    deleteBooksFromCart(id, reFetchData).then(
                                        () => {
                                            displayAlert(
                                                Success,
                                                DeletedFromCart,
                                                "success",
                                                Done,
                                                3000
                                            );
                                        }
                                    );
                                } else {
                                    addBooksToCart(id, 1, reFetchData).then(
                                        () => {
                                            displayAlert(
                                                Success,
                                                AddedToCart,
                                                "success",
                                                Done,
                                                3000
                                            );
                                        }
                                    );
                                }
                            }}
                        >
                            <img src="/images/carticon.svg" alt="/" />
                        </a>
                    )}
                    <div className=" flex">
                        <span>{campaign ? newPrice() + "$" : null}</span>
                        <span className={campaign ? "oldPrice" : ""}>
                            {/* {campaign ? "" : price} */}
                            {price}$
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
