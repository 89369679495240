import React from "react";
import { useState } from "react";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralServices";
import { displayAlert } from "../../../utils/misc";
import { setUser } from "../../../redux/actions-reducers/user";
import { useDispatch, useSelector } from "react-redux";
import Global from "../../../language/Global.json";

function HorizontalProduct({
    withDelete,
    fromOrders,
    reFetchData,
    data: { quantity, total_price, unit_price, book },
}) {
    const [qty, setQty] = useState(quantity);
    const [minOrder] = useState(book.min_order);
    const currentUser = useSelector(({ currentUser }) => currentUser);
    const language = useSelector(({ language }) => language.lang);
    const i18n = language === "ar" ? Global.ar : Global.en;
    const dispatch = useDispatch();
    const newPrice = () => {
        let discountAmount = (book?.price * book?.campaign?.percentage) / 100;

        let priceAfterDiscount = 0;
        if (discountAmount > book?.campaign?.fixed_amount) {
            priceAfterDiscount = book?.price - book?.campaign?.fixed_amount;

            return priceAfterDiscount;
        } else {
            priceAfterDiscount = book?.price - discountAmount;
            return priceAfterDiscount;
        }
    };

    const addBooksToCart = async (id, qty, reFetchData) => {
        const { success } = await generalServices.addToCart(id, qty);
        if (!success) return;
        !withDelete &&
            dispatch(setUser({ cart_count: currentUser.cart_count + 1 }));

        reFetchData();
    };

    const deleteBooksFromCart = async (id, reFetchData) => {
        const { success } = await generalServices.deleteBooksFromCart(id);
        const { Done, BookDeletedFromCart } = i18n;
        if (!success) return;
        displayAlert(Done, BookDeletedFromCart, "success");
        dispatch(setUser({ cart_count: currentUser.cart_count - 1 }));

        reFetchData();
    };
    const handleChange = ({ target: { value } }) => {
        let qty;
        // const { min_order, quantity } = this.state;
        if (value < minOrder) {
            // qty = minOrder;
            qty = +value;
        } else {
            if (value > book.quantity) {
                qty = book.quantity;
            } else {
                qty = +value;
            }
        }
        setQty(qty);
        addBooksToCart(book?.id, qty, reFetchData);
    };
    const handleBlur = () => {
        qty < minOrder && setQty(minOrder);
        addBooksToCart(book?.id, qty < minOrder ? minOrder : qty, reFetchData);
    };

    return (
        <div className="horizontal-product flex">
            <img
                src={
                    book?.main_image
                        ? apiService.imageLink + book?.main_image
                        : "/images/placeholder.png"
                }
                alt="l"
            />
            <div className="horizontal-product__contents">
                <div className="horizontal-product__contents__header flex">
                    <a href={`/productDetails/${book?.id}`}>{book?.title}</a>
                    {withDelete && (
                        <button
                            onClick={() =>
                                deleteBooksFromCart(book?.id, reFetchData)
                            }
                        >
                            <i className="fa fa-trash-alt"></i>
                        </button>
                    )}
                </div>
                <p>{book?.description}ss</p>

                <div className="horizontal-product__contents__footer flex">
                    <p>
                        {book.campaign
                            ? newPrice()
                            : parseInt(book?.price).toFixed(2)}
                        $
                    </p>
                    {/* qty of product start   */}

                    <div className="qty flex">
                        {fromOrders ? (
                            <>
                                <i class="fa fa-times"></i>
                                <span style={{ marginLeft: "5px" }}>
                                    {" "}
                                    {quantity}
                                </span>
                            </>
                        ) : (
                            <>
                                {/* mins  button start  */}
                                <span
                                    // onClick={() => {
                                    //     if (qty > 1) {
                                    //         setQty(qty - 1);
                                    //         addBooksToCart(
                                    //             book?.id,
                                    //             qty - 1,
                                    //             reFetchData
                                    //         );
                                    //     }
                                    // }}
                                    onClick={() => {
                                        if (qty > 1) {
                                            if (qty > minOrder) {
                                                setQty(qty - 1);
                                                addBooksToCart(
                                                    book?.id,
                                                    qty - 1,
                                                    reFetchData
                                                );
                                            } else {
                                                setQty(minOrder);
                                            }
                                        }
                                    }}
                                >
                                    <i className="fas fa-minus"></i>
                                </span>
                                {/* mins  button end  */}

                                {/* input (value) qty start  */}
                                <input
                                    type="tel"
                                    value={qty}
                                    // onChange={({ target: { value } }) => {
                                    //     if (value > 0) {
                                    //         if (value < book?.quantity) {
                                    //             setQty(value);
                                    //         }
                                    //     }
                                    // }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {/* input (value) qty end  */}

                                {/* plus button start  */}
                                <span
                                    onClick={() => {
                                        if (qty < book?.quantity) {
                                            setQty(qty + 1);
                                            addBooksToCart(
                                                book?.id,
                                                qty + 1,
                                                reFetchData
                                            );
                                        }
                                    }}
                                >
                                    <i className="fas fa-plus"></i>
                                </span>
                                {/* plus button end  */}

                                {/* qty of product end  */}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HorizontalProduct;
