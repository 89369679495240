import axios from "axios";

class ApiService {
    baseURL = process.env.REACT_APP_API_ENDPOINT;
    // baseURL = "https://cms.redwanpublisher.com/api";
    token = process.env.REACT_APP_API_TOKEN;

    get accessToken() {
        return window.localStorage.getItem("accessToken") ?? 0;
    }

    get language() {
        return window.localStorage.getItem("language") ?? "en";
    }

    authenticated() {
        return axios.create({ baseURL: this.baseURL, headers: { Token: this.token, Authorization: `Bearer ${this.accessToken}`, Language: this.language } });
    }

    unauthenticated() {
        return axios.create({ baseURL: this.baseURL, headers: { Token: this.token, Authorization: `Bearer ${this.accessToken}`, Language: this.language } });
    }

    storeToken(token) {
        window.localStorage.setItem("accessToken", token);
    }
    // imageLink = "http://192.168.1.64:8010/storage/";
    imageLink = "https://cms.caesarpublications.com/public/storage/";
}

export const apiService = new ApiService();
